export const tiltCDN = ()=>{
    let script = document.createElement("script");
    script.type = "text/javascript"; 
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.0/vanilla-tilt.min.js"; 
    script.defer = true;
    
    document.head.appendChild(script);
    console.log("Cargando el TILT")
}

export default tiltCDN