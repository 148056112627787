export const activeHeader = ()=>{

    // Botón de business en responsive
    if(window.screen.width<=992){
      let  btnBusinessResponsive = document.querySelector('.menu-item-4516 > a')
      btnBusinessResponsive.target = '_blank'
    }


    // Active
    let elementUrlPersonal =[...document.querySelectorAll('.personal-active-header > a')]
    
    // Botón de registro
    let btnRegister = document.querySelector('.header__contact > a') 



    // Función para cambiar de idioma
    const rutaInicioEn = '/en'
    const rutaInicioEs = '/es'
    const rutaInicioPT = '/pt'

    // Remover la clase Active por defecto

    elementUrlPersonal.forEach(link => {
        link.classList.remove('link-active-kuady')
    })
    
    // Aplicación de clase Active cuando ingresas a la web
    if (window.location.pathname === rutaInicioEn || window.location.pathname === rutaInicioEs){
        elementUrlPersonal[0].classList.add('link-active-kuady')
        elementUrlPersonal[1].classList.remove('link-active-kuady')
    } else{
        elementUrlPersonal[0].classList.remove('link-active-kuady')
        elementUrlPersonal[1].classList.add('link-active-kuady')

        elementUrlPersonal.forEach((link)=>{
            let pathName = link.pathname
            let contenido = link.textContent.toLowerCase()

            const urlActual = window.location.href;
            
            //console.log(contenido+ " EL PATH:  " + pathName)
    
            if(urlActual.includes(`/${contenido}/`) ){

                link.classList.add('link-active-kuady')          
                
            } else {
                link.classList.remove('link-active-kuady');
            }


        })
    }

    // Aplicación del activate si caambia de página



  // Botón de registo por idioma
  if (window.location.pathname.includes(rutaInicioEn)) {

    // Botón Business & Contacto
    if (window.location.pathname.includes('business')) {

      btnRegister.textContent = 'Contact us'
      btnRegister.href = '/en/business/contact/'
      btnRegister.target = '_self'

      // Borro el data de la etiqeuta y así no generar otro comportamiento
      delete btnRegister.dataset.toggle
      delete btnRegister.dataset.target

    } else {
      btnRegister.textContent = 'REGISTER'
      //btnRegister.href = '/en/personal/contact/'
      // btnRegister.href = '#modal'
      btnRegister.target = '_self'

      
      // Hago reset del href para que al hacer click no me redirija
      
      btnRegister.href = ""
      btnRegister.dataset.toggle="modal"
      btnRegister.dataset.target="#myModalQR"

    }
  } else if (window.location.pathname.includes(rutaInicioEs)) {
    // Botón Business
    if (window.location.pathname.includes('negocios')) {
      btnRegister.textContent = 'Contáctanos'
      btnRegister.href = '/es/negocios/contact/'
      btnRegister.target = '_self'

      // Borro el data de la etiqeuta y así no generar otro comportamiento
      delete btnRegister.dataset.toggle
      delete btnRegister.dataset.target


    } else {
      btnRegister.textContent = 'REGÍSTRATE'
      // btnRegister.href = '#modal'
      btnRegister.target = '_self'
      
      // Hago reset del href para que al hacer click no me redirija
      btnRegister.href = ""
      btnRegister.dataset.toggle="modal"
      btnRegister.dataset.target="#myModalQR"
    }
  } else {
    
      // Botón Business & Contacto
      if (window.location.pathname.includes('business')) {
        btnRegister.textContent = 'Contact us'
        btnRegister.href = '/en/business/contact/'
        btnRegister.target = '_self'
      } else {

        btnRegister.textContent = 'REGISTER'
      //   btnRegister.href = '/en/personal/contact/'
        btnRegister.href = '#myModalQR'
        btnRegister.target = '_self'
      }
  }
  
}

export default activeHeader