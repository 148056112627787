class Uploader {
  constructor () {
    this.test = [
      'media_upload_1',
      'media_upload_2',
      'media_upload_3',
      'media_upload_4',
      'media_upload_5'
    ]
    this.removeItem = this.removeItem.bind(this)
    this.init()
    this.fillListWhitItems(this.test)
  }

  init () {
    if (document.getElementsByClassName('ticket-form__media_uploads')[0]) {
      const buttonUploader = document.getElementsByClassName(
        'ticket-form__media_uploads'
      )[0]
      buttonUploader.addEventListener('click', this.filterInputsFile)
    }
  }

  filterInputsFile () {
    const inputsFile = [
      'media_upload_1',
      'media_upload_2',
      'media_upload_3',
      'media_upload_4',
      'media_upload_5'
    ]
    const listInput = []

    inputsFile.filter((elemInput) => {
      listInput.push(document.getElementById(elemInput))
    })
    if (listInput.length) {
      for (let inpt = 0; inpt < listInput.length; inpt++) {
        if (!listInput[inpt].value) {
          listInput[inpt].click()
          return
        }
      }
    }
  }

  fillListWhitItems (test) {
    const listOfselectedFiles = document.getElementById('listOfselectedFiles')
    const disclamer = document.getElementsByClassName('disclamer_bad_file')[0]
    const root_theme = encodeURI(scripts_ajax_variables.root_theme)
    for (let i = 0; i < test.length; i++) {
      if (document.getElementById(test[i])) {
        document.getElementById(test[i]).addEventListener('change', (fileGet) => {
          const filterImage = fileGet.target.files[0].type.split('/')
          if (filterImage[0] === 'image') {
            if (filterImage[1] === 'jpeg' || filterImage[1] === 'png') {
              listOfselectedFiles.innerHTML += `<li><span>${fileGet.target.files[0].name}</span>
                      <img src=${root_theme}/images/x.svg width='20px' height='20px' class="delette_${fileGet.target.name}" /></li>`
              this.removeItem(listOfselectedFiles)
            } else {
              fileGet.target.value = ''
              disclamer.style.display = 'block'
              setTimeout(() => {
                disclamer.style.display = 'none'
              }, 2500)
            }
          } else {
            fileGet.target.value = ''
            disclamer.style.display = 'block'
            setTimeout(() => {
              disclamer.style.display = 'none'
            }, 2500)
          }
        })
      }
    }// sicle for
  }

  removeItem (listOfselectedFiles) {
    for (let o = 0; o < listOfselectedFiles.children.length; o++) {
      listOfselectedFiles.children[o].lastChild.addEventListener(
        'click',
        (e) => {
          listOfselectedFiles.removeChild(e.target.parentElement)
          const resetInput = e.target.classList[0].replace('delette_', '')
          document.getElementsByName(resetInput)[0].value = ''
        }
      )
    }
  }
}

export default Uploader
