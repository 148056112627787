export const videoFullBarba = ()=>{
    //window.innerWidth
    // if (window.matchMedia('(max-width: 992px)').matches)
        if(window.innerWidth < 993){
        const video = document.querySelector('.video-pick-card video')
        video.style.display = 'none'
        video.remove();
    }
}

export default videoFullBarba