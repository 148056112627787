//  HERO PERSONAL MOBILE

const cargaInicialHeroMobile = () => {
  let grados = 0

  const frontImage = document.querySelector('.front img')
  const backImage = document.querySelector('.back img')
  const flipper = document.querySelector('.flipper')

  //SOMBRA IMG

  backImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'
  frontImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'

  let isFlipped = false
  let currentIndex = 0
  let mainImg = [
    'https://www.kuady.com/resources/web-imgs/mobile-hero/en/card-screen.png',
    'https://www.kuady.com/resources/web-imgs/mobile-hero/en/pay-screen.png',
    'https://www.kuady.com/resources/web-imgs/mobile-hero/en/T-shirt.png'
  ]

  // Precarga de imagenes (Sino no funciona)
  for (let i = 0; i < mainImg.length; i++) {
    const img = new Image()
    img.src = mainImg[i]
  }

  // Establecer la primera imagen al inicio
  frontImage.src = mainImg[0]
  backImage.src = mainImg[1]

  setInterval(() => {
    if (isFlipped) {
      grados = grados + 180
      flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      frontImage.src = mainImg[currentIndex]
    } else {
      grados = grados + 180
      flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      backImage.src = mainImg[currentIndex]
    }
    isFlipped = !isFlipped

    // Mostrar las imágenes cuando haya terminado la transición
    setTimeout(() => {
      frontImage.style.visibility = 'visible'
      backImage.style.visibility = 'visible'
    }, 1000) // Ajuste según el CSS
  }, 5000)
}

export const conditionHeroMobile = () => {
  if (window.screen.width < 577) {
    cargaInicialHeroMobile()
  }
}

export default conditionHeroMobile
