export const carousel = ()=>{
    let script = document.createElement("script");
    script.type = "text/javascript"
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    script.defer = true;

    let script2 = document.createElement("script")
    script2.type = "text/javascript"
    script2.src = "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js" 
    script2.defer = true;

    let link =  document.createElement("link")
    link.rel="stylesheet"
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css'

    // let script3 = document.createElement("script");
    // script3.type = "text/javascript"
    // script3.defer = true;
    // script3.text = `
    //             $(document).ready(function(){
    //                 $(".custom-carousel").owlCarousel({
    //                     autoWidth: true,
    //                     loop: true
    //                 });
    //             });
    //         ` 
    
    document.head.appendChild(script)
    document.head.appendChild(script2)
    document.head.appendChild(link);

    // document.body.appendChild(script3)
}

export default carousel