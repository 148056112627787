import { setNexStep } from './helpper/eventhelpper'
class TrigguerEvents {
  constructor () {
    this.heroTrigguer = document.getElementById('event_heroTriguer')
    this.heroTrigguer ? this.init(this.heroTrigguer) : null
  }

  init (heroBtn) {
    heroBtn.addEventListener('click', this.heroBtnOpenPannel)
  }

  heroBtnOpenPannel (event) {
    event.preventDefault()
    setNexStep().bodyStyle.overflow = 'hidden'
    setNexStep().openPannel.style.display = 'flex'
    setNexStep().dataEvemt.innerHTML = `<div class="popUp_Events-wrapper-header-right-name-wrapperInfo">
                 <img src="${setNexStep().imageBig.children[0].currentSrc}" width="120px">
                <ul><li>${setNexStep().dateEvent.outerHTML}</li><li>${setNexStep().nameEvent.children[0].outerHTML}</li></ul></div>`
  }
}
export default TrigguerEvents
