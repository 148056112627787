//  ICONOS HERO PERSONAL

export const iconsHero = () => {
  let videoContainersHome = [
    ...document.querySelectorAll('.autoplay-video-container')
  ]

  // Habilitar el video para que se reproduzca si hace scroll

  let userInteracted = false
  const enableVideoPlayback = () => {
    userInteracted = true
    document.removeEventListener('scroll', enableVideoPlayback)
  }
  document.addEventListener('scroll', enableVideoPlayback)

  videoContainersHome.forEach((containerVid) => {
    let videoIcon = containerVid.querySelector(
      '.features__feature-image > .video-icon'
    )

    console.log(videoIcon)

    containerVid.addEventListener('mouseover', function () {
      if (userInteracted && videoIcon && videoIcon.paused) {
        videoIcon.play().catch((error) => {
          console.error('Error attempting to play the video:', error)
        })
      }
    })

    containerVid.addEventListener('mouseout', function () {
      if (userInteracted && videoIcon) {
        videoIcon.pause()
      }
    })
  })
}

export default iconsHero
