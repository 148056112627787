export const imgHero = () => {
  let grados = 0

  const frontImage = document.querySelector('.front img')
  const backImage = document.querySelector('.back img')
  const flipper = document.querySelector('.flipper')

  //SOMBRA IMG

  backImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2));'
  frontImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'

  let isFlipped = false
  let currentIndex = 0
  let mainImg = [
    '/wp-content/themes/kuady/images/web-hero-imgs/app-1.svg',
    '/wp-content/themes/kuady/images/web-hero-imgs/app-2.svg',
    '/wp-content/themes/kuady/images/web-hero-imgs/app-3.svg'
  ]

  // Precargar las imágenes
  for (let i = 0; i < mainImg.length; i++) {
    const img = new Image()
    img.src = mainImg[i]
  }

  // Establecer la primera imagen al inicio
  frontImage.src = mainImg[0]
  backImage.src = mainImg[1] // Establecer la siguiente imagen

  setInterval(() => {
    if (isFlipped) {
      grados = grados + 180
      //flipper.style.transform = 'rotateY(0deg)';
      flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      frontImage.src = mainImg[currentIndex]
    } else {
      grados = grados + 180
      //flipper.style.transform = 'rotateY(180deg)';
      flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      backImage.src = mainImg[currentIndex]
    }
    isFlipped = !isFlipped

    // Mostrar las imágenes cuando haya terminado la transición
    setTimeout(() => {
      frontImage.style.visibility = 'visible'
      backImage.style.visibility = 'visible'
    }, 1000) // Ajuste según el CSS
  }, 5000)

  // Rotación Hover

  let card = document.querySelector('.card')

  //  document.addEventListener('mousemove', function (e) {
  //    let xAxis = -((window.innerWidth / 2 - e.pageX) / 25)
  //    let yAxis = (window.innerHeight / 2 - e.pageY) / 12
  //    card.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`
  //  })
  document.addEventListener('mousemove', function (e) {
    let xAxis = -((window.innerWidth / 2 - e.pageX) / 60)
    let yAxis = (window.innerHeight / 2 - e.pageY) / 24
    card.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`
  })

  //PRUEBA DE ROBERT

  const imgLeft = () => {
    const divs = document.querySelectorAll('.card-1, .skuad-2, .money-3')
    let index = 0

    divs.forEach((div, i) => {
      if (i !== 0) {
        div.style.display = 'none'
      } else {
        div.style.display = 'block'
      }
    })

    setInterval(() => {
      divs.forEach((div) => {
        div.style.opacity = 0
        div.style.transform = 'scale(0.8) rotateY(90deg)'
      })

      setTimeout(() => {
        divs[index].style.display = 'none'
        index = (index + 1) % divs.length
        divs[index].style.display = 'block'

        setTimeout(() => {
          divs[index].style.opacity = 1
          divs[index].style.transform =
            'scale(1) rotateY(0deg) translateZ(25px)'
        }, 50) // Añadir un pequeño retraso para evitar que la transición sea instantánea
      }, 500) // Tiempo de transición
    }, 5000) // Cambio cada 5 segundos
  }

  imgLeft()

  // IMG DERECHA

  const imgRight = () => {
    const divs = document.querySelectorAll(
      '.ticket-1, .phone-card, .card-icons'
    )
    let index = 0

    divs.forEach((div, i) => {
      if (i !== 0) {
        div.style.display = 'none'
      } else {
        div.style.display = 'block'
      }
    })

    setInterval(() => {
      divs.forEach((div) => {
        div.style.opacity = 0
        div.style.transform = 'scale(0.8) rotateY(90deg)'
      })

      setTimeout(() => {
        divs[index].style.display = 'none'
        index = (index + 1) % divs.length
        divs[index].style.display = 'block'

        setTimeout(() => {
          //  divs[index].style.opacity = 1
          // IF para conseguir que el ticket esté por debajo del wireframe
          if (divs[index].className === 'ticket-1') {
            divs[index].style.opacity = 1
            divs[index].style.transform =
              'scale(1) rotateY(0deg) translateZ(-35px)'
          } else {
            divs[index].style.opacity = 1
            divs[index].style.transform =
              'scale(1) rotateY(0deg) translateZ(25px)'
          }
        }, 50)
      }, 500)
    }, 5000)
  }

  imgRight()
}
